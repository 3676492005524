<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addLossDetailsReport">
      <div class="row" v-if="isAllowed('accident-incident.general.edit') && isOwnVessel()">
        <div class="col-12 text-right">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="cancel">Cancel
          </button>
          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Loss Details</button>
          <button class="e-btn e-btn-blue ml-2" type="submit" v-if="toEdit">Save</button>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td colspan="2">
            <h6 class="font-weight-bold">General</h6>
          </td>
        </tr>

        <template v-if="toEdit">
          <tr>
            <td style="width: 30%">Is Schedule Delay ?</td>
            <td>
              <select name="sched_delay"  v-model="lossDetails.is_delay_schedule" class="form-control w-auto form-control-sm my-2">
                <option :value="1">YES</option>
                <option :value="0">NO</option>
              </select>
            </td>
          </tr>
          <template  v-if="lossDetails.is_delay_schedule">
            <tr>
              <td style="width: 30%">Delay Schedule <sub class="lead e-text-red font-weight-bold">*</sub></td>
              <td>
                <!--            <input type="text" class="form-control form-control-sm" v-model="lossDetails.loss_sched_day">-->
                <div class="form-inline">
                  <div class="form-group mb-2 mr-5">
                    <label for="loss_sched_day" style="font-size: 13px" class="mr-2 font-weight-bold">Day</label>
                    <input type="number" min="0" class="form-control form-control-sm" id="loss_sched_day"
                           placeholder="Enter Day"
                           v-model="lossDetails.delay_sched_day">
                  </div>
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="loss_sched_day" style="font-size: 13px" class="mr-2 font-weight-bold">Hour</label>
                    <input type="number" min="0" max="23" class="form-control form-control-sm" id="loss_sched_hour"
                           placeholder="Enter hour"
                           v-model="lossDetails.delay_sched_hour">
                  </div>

                  <div class="form-group mx-sm-3 mb-2">
                    <label for="loss_sched_day" style="font-size: 13px" class="mr-2 font-weight-bold">Minute</label>
                    <input type="number" min="0" class="form-control form-control-sm" id="loss_sched_hour" max="59"
                           placeholder="Enter minute"
                           v-model="lossDetails.delay_sched_minute">
                  </div>

                </div>
              </td>

            </tr>
            <tr>
              <td>Detail Schedule Remarks <sub class="lead e-text-red font-weight-bold">*</sub></td>
              <td v-if="toEdit">
            <textarea rows="5" class="form-control form-control-sm mb-1 pre-wrap-txt"
                      v-model="lossDetails.delay_sched_remarks"></textarea>
              </td>
            </tr>
          </template>

        </template>
        <template v-else>
          <tr>
            <td style="width: 30%">Is Schedule Delay ?</td>
            <td>
                {{ accidentIncidentReport.loss.is_delay_schedule ? 'YES' : 'NO'}}
            </td>
          </tr>
          <template v-if="accidentIncidentReport.loss.is_delay_schedule">
            <tr>
              <td style="width: 30%">Delay Schedule</td>
              <td>
                <div class="form-inline">
                  <div class="form-group mb-2 mr-5">
                    {{ accidentIncidentReport.loss.delay_sched_day ? accidentIncidentReport.loss.delay_sched_day : '0' }}
                    <span
                      class="ml-1">Day/s</span>
                  </div>

                  <div class="form-group mb-2 mr-5">
                    {{ accidentIncidentReport.loss.delay_sched_hour ? accidentIncidentReport.loss.delay_sched_hour : '0' }}
                    <span
                      class="ml-1">Hour/s</span>
                  </div>

                  <div class="form-group mb-2 mr-5">
                    {{
                      accidentIncidentReport.loss.delay_sched_minute ? accidentIncidentReport.loss.delay_sched_minute : '0'
                    }} <span
                    class="ml-1">Minute/s</span>
                  </div>

                  <div class="form-group mb-2 mr-5">
                    <span class="mr-2 font-weight-bold" style="font-size: 13px">Total:</span>
                    {{
                      setTotalHours(accidentIncidentReport.loss.delay_sched_day, accidentIncidentReport.loss.delay_sched_hour, accidentIncidentReport.loss.delay_sched_minute)
                    }} Hour/s
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Detail Schedule Remarks</td>
              <td>
                <p class="pre-wrap-txt textarea-display-card mb-1">
                  {{ accidentIncidentReport.loss.delay_sched_remarks }}
                </p>
              </td>
            </tr>
          </template>

        </template>

        <br>
        <br>
      </table>
      <table class="w-100">
        <tr>
          <td style="width: 30%">
            Estimated Loss Summary
          </td>
          <td>
            <p class="mb-0" v-if="accidentIncidentReport.loss.estimated_cost">
              {{ formatToMoney(accidentIncidentReport.loss.estimated_cost) }} {{ accidentIncidentReport.loss.estimated_currency }}
            </p>
          </td>
        </tr>
        <tr>
          <td style="width: 30%">
            Loss Summary
          </td>
          <td>
            <template v-for="summary in accidentIncidentReport.loss.lossSummary">
              <p class="mb-0" v-if="summary.amount">
                {{ formatToMoney(summary.amount) }} {{ summary.code }}
              </p>
            </template>
          </td>
        </tr>
      </table>

      <!--      <table class="table table-sm table-bordered small">-->
      <!--        <thead>-->
      <!--        <tr>-->
      <!--          <th class="align-middle"><h6 class="font-weight-bold mb-0">CURRENCY</h6></th>-->
      <!--          <th class="align-middle"><h6 class="font-weight-bold mb-0">TOTAL AMOUNT</h6></th>-->
      <!--        </tr>-->
      <!--        </thead>-->
      <!--        <tbody>-->
      <!--        <tr v-for="summary in accidentIncidentReport.loss.lossSummary">-->
      <!--          <template v-if="summary.amount">-->
      <!--            <td style="width: 30%"><h6 class="font-weight-bolder mb-0">{{ summary.name }} ({{ summary.code }})</h6></td>-->
      <!--            <td style="width: 80%"><h6 class="font-weight-bolder mb-0">{{ formatToMoney(summary.amount) }}</h6></td>-->
      <!--          </template>-->
      <!--        </tr>-->
      <!--        </tbody>-->
      <!--      </table>-->
      <nav class="mt-4">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-Personnel-tab" data-toggle="tab" href="#nav-Personnel" role="tab"
             :class="accidentIncidentReport.loss.personnel.length ? 'font-weight-bold bg-highlight-red' : ''"
             aria-controls="nav-Personnel" aria-selected="true">
            Personnel
          </a>
          <a class="nav-item nav-link" id="nav-HullMachinery-tab" data-toggle="tab" href="#nav-HullMachinery" role="tab"
             aria-controls="nav-HullMachinery" aria-selected="false"
             :class="accidentIncidentReport.loss.hm_cost || accidentIncidentReport.loss.hm_cost_details ? 'font-weight-bold bg-highlight-red' : ''">
            Hull and Machinery
          </a>
          <a class="nav-item nav-link" id="nav-CargoDamage-tab" data-toggle="tab" href="#nav-CargoDamage" role="tab"
             aria-controls="nav-CargoDamage" aria-selected="false"
             :class="accidentIncidentReport.loss.cd_cost || accidentIncidentReport.loss.cd_cost_details ? 'font-weight-bold bg-highlight-red' : ''">
            Cargo Damage
          </a>
          <a class="nav-item nav-link" id="nav-Environmental-tab" data-toggle="tab" href="#nav-Environmental" role="tab"
             aria-controls="nav-Environmental" aria-selected="false"
             :class="accidentIncidentReport.loss.e_cost || accidentIncidentReport.loss.e_cost_details ? 'font-weight-bold bg-highlight-red' : ''">
            Environmental
          </a>
          <a class="nav-item nav-link" id="nav-ThirdParty-tab" data-toggle="tab" href="#nav-ThirdParty" role="tab"
             aria-controls="nav-ThirdParty" aria-selected="false"
             :class="accidentIncidentReport.loss.tp_cost || accidentIncidentReport.loss.tp_cost_details ? 'font-weight-bold bg-highlight-red' : ''">
            Third Party
          </a>
        </div>
      </nav>
      <div class="tab-content pt-2" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-Personnel" role="tabpanel" aria-labelledby="nav-Personnel-tab">
          <div>
            <div class="row mb-2">
              <div class="col-8">
                <h6 class="font-weight-bold">Personnel</h6>
              </div>
              <div class="col-4" v-if="isAllowed('accident-incident.loss.personnel.create') && isOwnVessel()">
              <div class="text-right">
                  <!--                  @click.prevent="addPersonelToList"-->
                  <!--                  v-show="toEdit"-->
                  <button type="button" class="e-btn e-btn-green" data-target="#crewPersonnel" data-toggle="modal">Add
                    Crew with Injury or Illness
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th class="text-center">RANK</th>
                    <th class="text-center">NAME</th>
                    <th class="text-center">AGE</th>
                    <th class="text-center">EMBARK DATE</th>
                    <th class="text-center">TIME ONBOARD</th>
                    <th class="text-center">MANNING</th>
                    <th class="text-center">NATIONALITY</th>
                    <th class="text-center">ACTION</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="isAllowed('accident-incident.loss.personnel.view')" v-for="(person,index) in personnelList">
                    <td class="text-center">{{ (!!person.rank ? person.rank : '').toUpperCase() }}</td>
                    <td class="text-center">{{ (!!person.last_name ? person.last_name : '').toUpperCase() }},
                      {{ person.first_name.toUpperCase() }}
                      {{ (!!person.middle_name ? person.middle_name : '').substring(0, 1).toUpperCase() }}
                    </td>
                    <td class="text-center">{{ person.age }}</td>
                    <td class="text-center">{{ person.embarkationDate }}</td>
                    <td class="text-center">{{ person.duration }}</td>
                    <td class="text-center">{{ (person.manning ? person.manning : '').toUpperCase() }}</td>
                    <td class="text-center">{{ (person.nationality ? person.nationality : '').toUpperCase() }}</td>
                    <td class="text-center">
                      <button v-if="isAllowed('accident-incident.loss.personnel.edit')" type="button" class="btn btn-info btn-xs mr-2" data-target="#crewPersonnel"
                              data-toggle="modal" @click="viewPersonnel(person,index)">
                        <font-awesome-icon icon="eye" class="force-white-all"/>
                        View
                      </button>
                      <button v-if="isAllowed('accident-incident.loss.personnel.delete')" class="btn btn-outline-danger btn-xs" type="button" @click.prevent="removePersonnel(person.id)">
                        <font-awesome-icon icon="trash" class="force-danger-all"/>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
            <div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" id="crewPersonnel">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content w-100">
                  <div class="modal-header w-100">
                    <div class="row w-100 align-items-center">
                      <div class="col-8">
                        <h5 class="modal-title">{{ toEditPersonnel ? 'Update ' : 'Add ' }}Personnel</h5>
                      </div>
                      <div class="col-4 text-right">
                        <button type="button" class="btn btn-xs mr-2 btn-warning" @click="updateInjuredPersonnel"
                                v-show="readOnly === false">
                          Update
                        </button>
                        <button type="button" class="btn btn-xs mr-2 btn-warning" v-show="toEditPersonnel && readOnly"
                                @click="readOnly=false">
                          Edit Details
                        </button>
                        <button type="button" class="btn btn-xs mr-2 btn-success" v-show="!toEditPersonnel"
                                @click.prevent="addInjuredPersonnel">Add Personnel
                        </button>
                        <button class="btn btn-xs btn-secondary" type="button" data-dismiss="modal"
                                @click="resetPersonnelRecord" aria-label="Close">
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="modal-body" style="max-height:80vh; overflow-y: auto;">
                    <table class="w-100 mt-2">
                      <tr>
                        <td style="width: 30%">Name of Crew</td>
                        <td>
                          <select name="selectCrewPersonnel"
                                  class="form-control form-control-sm mb-1 text-uppercase"
                                  @change="changeCrewName($event,currentIndex)"
                                  :disabled="toEditPersonnel && readOnly"
                                  v-model="personnelRecord.crew_id">
                            <option :value="null">--- SELECT CREW ---</option>
                            <option :value="crew.id" v-for="crew in crewKeys">{{ crew.rank }} - {{ !!crew ? crew.last_name.toUpperCase() : '' }},
                              {{ !!crew ? crew.first_name.toUpperCase() : '' }} {{ crew.middle_name ? crew.middle_name.substring(0, 1) : '' }}.
                            </option>
                          </select>
                          <span v-if="errors.crew_id"
                                class="text-danger font-weight-bold">{{ errors.crew_id[0] }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <td>
                          <div class="form-inline">
                            <div class="form-group mr-2">
                              <input type="number" required class="form-control form-control-sm" id="loss_sched_day"
                                     min="0"
                                     step="0.01"
                                     :disabled="toEditPersonnel && readOnly"
                                     v-model="personnelRecord.personnel_costs"
                                     placeholder="Enter personnel cost">
                            </div>
                            <div class="form-group">
                              <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                                      :disabled="toEditPersonnel && readOnly"
                                      v-model="personnelRecord.currency_id">
                                <option :value="null">--- SELECT CURRENCY ---</option>
                                <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                  {{ currency.code }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Cost Detail</td>
                        <td width="100%"><textarea rows="5"
                                                   :disabled="toEditPersonnel && readOnly"
                                                   class="form-control form-control-sm mb-1 pre-wrap-txt"
                                                   v-model="personnelRecord.lost_details"></textarea>
                        </td>
                      </tr>
                      <tr>
                        <td>Medical Type</td>
                        <td width="100%">
                          <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                                  :disabled="toEditPersonnel && readOnly"
                                  v-model="personnelRecord.medical_type_id">
                            <option :value="null">--- SELECT MEDICAL TYPE ---</option>
                            <option v-for="medicalType in medicalTypeKeys" :key="medicalType.id"
                                    :value="medicalType.id">
                              {{ medicalType.name }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Extent of {{ entity }}</td>
                        <td width="100%">
                          <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                                  :disabled="toEditPersonnel && readOnly"
                                  v-model="personnelRecord.medical_severity_id">
                            <option :value="null">--- SELECT EXTENT OF {{ entity }} ---</option>
                            <option v-for="severity in medicalSeverityKeys" :key="severity.id" :value="severity.id">
                              {{ severity.name }} {{ severity.code ? `(${severity.code})` : '' }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Complaint</td>
                        <td width="100%"><textarea rows="5" class="form-control form-control-sm mb-1 pre-wrap-txt"
                                                   :disabled="toEditPersonnel && readOnly"
                                                   v-model="personnelRecord.complaint"></textarea>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ entity }}</td>
                        <td width="100%">
                          <multi-select-custom
                            :disabled="toEditPersonnel && readOnly"
                            id="ailments"
                            :items="ailmentKey" :entity="entity"
                            @doneSelected="setAilments"
                            :selected="personnelRecord.ailment_id">
                          </multi-select-custom>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ entity }} Parts</td>
                        <td width="100%">
                          <multi-select-custom
                            id="ailmentParts"
                            :disabled="toEditPersonnel && readOnly"
                            :items="ailmentPartKeys" entity="INJURED PARTS"
                            @doneSelected="setAilmentParts"
                            :selected="personnelRecord.ailment_part_id">
                          </multi-select-custom>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ entity }} Treatment</td>
                        <td width="100%">
                          <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                                  :disabled="toEditPersonnel && readOnly"
                                  v-model="personnelRecord.medical_treatment_id">
                            <option :value="null">--- SELECT {{ entity }} TREATMENT ---</option>
                            <option v-for="treatment in medicalTreatmentKeys" :key="treatment.id" :value="treatment.id">
                              {{ treatment.name }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ entity }} Reason</td>
                        <td width="100%">
                          <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                                  :disabled="toEditPersonnel && readOnly"
                                  v-model="personnelRecord.ailment_reason_id">
                            <option :value="null">--- {{ entity }} REASON ---</option>
                            <option v-for="reason in ailmentReasonKeys" :key="reason.id" :value="reason.id">
                              {{ reason.name }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Details of {{ entity }}</td>
                        <td width="100%"><textarea :disabled="toEditPersonnel && readOnly" rows="5"
                                                   class="form-control form-control-sm mb-1 pre-wrap-txt"
                                                   v-model="personnelRecord.ailment_details"></textarea>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ entity }} Files</td>
                        <td width="100%">
                          <template v-if="toEditPersonnel && readOnly">
                            <template v-if="attachments.length">
                              <div class="row">
                                <template v-for="attachment in attachments">
                                  <div class="col-4">
                                    <other-comp v-if="!attachment.isImage"
                                                @viewOther="viewPersonnelAttachment"
                                                @deleteOther="removePersonnelAttachment"
                                                :attachment="attachment"></other-comp>
                                    <image-comp v-else @initDownload="downloadPersonnelAttachment"
                                                @initView="viewPersonnelAttachment"
                                                @initDelete="removePersonnelAttachment"
                                                :image-info="attachment">
                                    </image-comp>
                                  </div>
                                </template>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            <input type="file"
                                   accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.csv"
                                   name="attachments" id="attachments" multiple>
                          </template>

                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-HullMachinery" role="tabpanel" aria-labelledby="nav-HullMachinery-tab">
          <!--       LOSS HULL  ///////////////////////////////////////////////////////////////////////////////////// -->
          <table class="w-100">
            <tr>

              <td style="width: 30%">
                <h6 class="font-weight-bold">Hull and Machinery</h6>
              </td>
              <td v-if="toEdit">
                <div class="form-inline">
                  <div class="form-group mb-2 mr-5">
                    <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                            v-model="lossDetails.hm_status">
                      <option value="1">N/A</option>
                      <option value="2">Occurred</option>
                    </select>
                  </div>
                </div>
              </td>
              <!--          <td v-else>{{ accidentIncidentReport.loss_hull_cost }} USD</td>-->
            </tr>

            <!--       LOSS HULL  ///////////////////////////////////////////////////////////////////////////////////// -->
            <!--          v-if="lossDetails.loss_hull_status == 2"-->
            <tr v-if="lossDetails.hm_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost</td>
                <td>
                  <div class="form-inline">
                    <div class="form-group mb-2 mr-2">
                      <input type="number" min="0" step="0.01" class="form-control form-control-sm" id="loss_sched_day"
                             placeholder="Enter hull cost"
                             v-model="lossDetails.hm_cost">
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                              v-model="lossDetails.hm_currency_id">
                        <option :value="null">--- SELECT CURRENCY ---</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                          {{ currency.code }}
                        </option>
                      </select>
                    </div>

                  </div>
                </td>
              </template>
            </tr>


            <tr v-if="!toEdit">
              <td style="width: 30%">Cost</td>
              <td>
                {{ accidentIncidentReport.loss.hm_cost ? accidentIncidentReport.loss.hm_cost : '0.00' }}
                {{ accidentIncidentReport.loss.hmCurrency ? accidentIncidentReport.loss.hmCurrency : 'N/A' }}
              </td>

            </tr>

            <tr v-if="lossDetails.hm_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost Detail</td>
                <td>
                  <div>
            <textarea v-model="lossDetails.hm_cost_details" rows="5"
                      class="form-control form-control-sm pre-wrap-txt"></textarea>
                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost Detail</td>
              <td>
                <p class="pre-wrap-txt textarea-display-card mb-1">
                  {{
                    accidentIncidentReport.loss.hm_cost_details ? accidentIncidentReport.loss.hm_cost_details : 'N/A'
                  }}
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="100%">
                <h6 class="font-weight-bold">Machinery / Equipment</h6>
              </td>
            </tr>
            <tr>
              <td>Equipment Manufacturer</td>
              <td class="text-uppercase">
            <textarea rows="2" class="form-control form-control-sm mb-1 pre-wrap-txt"
                      v-model="lossDetails.hm_equipment_manufacturer"
                      v-if="toEdit"
            ></textarea>
                <span class="pre-wrap-txt" v-else>{{ accidentIncidentReport.loss.hm_equipment_manufacturer }}</span>
              </td>
            </tr>
            <tr>
              <td>Equipment Model</td>
              <td class="text-uppercase">
            <textarea rows="2" class="form-control form-control-sm mb-1 pre-wrap-txt"
                      v-model="lossDetails.hm_equipment_model"
                      v-if="toEdit"
            ></textarea>
                <span class="pre-wrap-txt" v-else>{{ accidentIncidentReport.loss.hm_equipment_model }}</span>
              </td>
            </tr>
            <tr>
              <td>Related Part Numbers</td>
              <td class="text-uppercase">
            <textarea rows="2" class="form-control form-control-sm mb-1 pre-wrap-txt"
                      v-model="lossDetails.hm_part_number"
                      v-if="toEdit"
            ></textarea>
                <span class="pre-wrap-txt" v-else>{{ accidentIncidentReport.loss.hm_part_number }}</span>
              </td>
            </tr>

          </table>
          <!--       LOSS HULL  ///////////////////////////////////////////////////////////////////////////////////// -->
        </div>
        <div class="tab-pane fade" id="nav-CargoDamage" role="tabpanel" aria-labelledby="nav-CargoDamage-tab">
          <!--       CARGO DAMAGE ///////////////////////////////////////////////////////////////////////////////////// -->
          <table class="w-100">
            <tr>
              <td style="width: 30%">
                <h6 class="font-weight-bold">Cargo Damage</h6>
              </td>
              <td v-if="toEdit">
                <div class="form-inline">
                  <div class="form-group mb-2 mr-5">
                    <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                            v-model="lossDetails.cd_status">
                      <option value="1">N/A</option>
                      <option value="2">Occurred</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="lossDetails.cd_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost</td>
                <td>
                  <div class="form-inline">
                    <div class="form-group mb-2 mr-2">
                      <input type="number" min="0" step="0.01" class="form-control form-control-sm" id="loss_sched_day"
                             placeholder="Enter hull cost"
                             v-model="lossDetails.cd_cost">
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                              v-model="lossDetails.cd_currency_id">
                        <option :value="null">--- SELECT CURRENCY ---</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                          {{ currency.code }}
                        </option>
                      </select>
                    </div>
                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost</td>
              <td>
                {{ accidentIncidentReport.loss.cd_cost ? accidentIncidentReport.loss.cd_cost : '0.00' }}
                {{ accidentIncidentReport.loss.cdCurrency ? accidentIncidentReport.loss.cdCurrency : 'N/A' }}
              </td>
            </tr>


            <!--        <tr>-->
            <!--          <td style="width: 30%">Cost</td>-->
            <!--          <td v-if="toEdit">-->
            <!--            <input type="number" step=".01" class="form-control form-control-sm" v-model="lossDetails.loss_cargo_cost"/>-->
            <!--          </td>-->
            <!--          <td v-else>{{ accidentIncidentReport.loss_cargo_cost }} USD</td>-->
            <!--        </tr>-->

            <tr v-if="lossDetails.cd_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost Detail</td>
                <td>
                  <div>
            <textarea v-model="lossDetails.cd_cost_details" rows="5"
                      class="form-control form-control-sm pre-wrap-txt"></textarea>
                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost Detail</td>
              <td>
                <p class="pre-wrap-txt textarea-display-card mb-1">
                  {{
                    accidentIncidentReport.loss.cd_cost_details ? accidentIncidentReport.loss.cd_cost_details : 'N/A'
                  }}
                </p>
              </td>
            </tr>


            <!--        <tr>-->
            <!--          <td>Cost Detail</td>-->
            <!--          <td v-if="toEdit">-->
            <!--            <textarea v-model="lossDetails.loss_cargo_detail" rows="5"-->
            <!--                      class="form-control form-control-sm pre-wrap-txt"></textarea>-->
            <!--          </td>-->
            <!--          <td class="pre-wrap-txt" v-else>{{ accidentIncidentReport.loss_cargo_detail }}</td>-->
            <!--        </tr>-->


          </table>
          <!--       CARGO DAMAGE ///////////////////////////////////////////////////////////////////////////////////// -->
        </div>
        <div class="tab-pane fade" id="nav-Environmental" role="tabpanel" aria-labelledby="nav-Environmental-tab">
          <!--       ENVIRONMENTAL  ///////////////////////////////////////////////////////////////////////////////////// -->
          <table class="w-100">
            <tr>
              <td style="width: 30%">
                <h6 class="font-weight-bold">Environmental</h6>
              </td>
              <td v-if="toEdit">
                <div class="form-inline">
                  <div class="form-group mb-2 mr-5">
                    <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                            v-model="lossDetails.e_status">
                      <option value="1">N/A</option>
                      <option value="2">Occurred</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>


            <tr v-if="lossDetails.e_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost</td>
                <td>
                  <div class="form-inline">
                    <div class="form-group mb-2 mr-2">
                      <input type="number" min="0" step="0.01" class="form-control form-control-sm" id="loss_sched_day"
                             placeholder="Enter environmental cost"
                             v-model="lossDetails.e_cost">
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                              v-model="lossDetails.e_currency_id">
                        <option :value="null">--- SELECT CURRENCY ---</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                          {{ currency.code }}
                        </option>
                      </select>
                    </div>

                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost</td>
              <td>
                {{ accidentIncidentReport.loss.e_cost ? accidentIncidentReport.loss.e_cost : '0.00' }}
                {{ accidentIncidentReport.loss.eCurrency ? accidentIncidentReport.loss.eCurrency : 'N/A' }}
              </td>
            </tr>


            <tr v-if="lossDetails.e_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost Detail</td>
                <td>
                  <div>
            <textarea v-model="lossDetails.e_cost_details" rows="5"
                      class="form-control form-control-sm pre-wrap-txt"></textarea>
                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost Detail</td>
              <td>
                <p class="pre-wrap-txt textarea-display-card mb-1">
                  {{ accidentIncidentReport.loss.e_cost_details ? accidentIncidentReport.loss.e_cost_details : 'N/A' }}
                </p>
              </td>
            </tr>

          </table>
          <!--       ENVIRONMENTAL  ///////////////////////////////////////////////////////////////////////////////////// -->

        </div>
        <div class="tab-pane fade" id="nav-ThirdParty" role="tabpanel" aria-labelledby="nav-ThirdParty-tab">
          <!--       THIRD PARTY  ///////////////////////////////////////////////////////////////////////////////////// -->
          <table class="w-100">
            <tr>
              <td style="width: 30%">
                <h6 class="font-weight-bold">Third Party</h6>
              </td>
              <td v-if="toEdit">
                <div class="form-inline">
                  <div class="form-group mb-2 mr-5">
                    <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                            v-model="lossDetails.tp_status">
                      <option value="1">N/A</option>
                      <option value="2">Occurred</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>


            <tr v-if="lossDetails.tp_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost</td>
                <td>
                  <div class="form-inline">
                    <div class="form-group mb-2 mr-2">
                      <input type="number" class="form-control form-control-sm" id="loss_sched_day"
                             min="0" step="0.01"
                             placeholder="Enter Third Party cost"
                             v-model="lossDetails.tp_cost">
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                      <select name="" class="form-control form-control-sm mb-1 text-uppercase"
                              v-model="lossDetails.tp_currency_id">
                        <option :value="null">--- SELECT CURRENCY ---</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                          {{ currency.code }}
                        </option>
                      </select>
                    </div>

                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost</td>
              <td>
                {{ accidentIncidentReport.loss.tp_cost ? accidentIncidentReport.loss.tp_cost : '0.00' }}
                {{ accidentIncidentReport.loss.tpCurrency ? accidentIncidentReport.loss.tpCurrency : 'N/A' }}
              </td>
            </tr>


            <tr v-if="lossDetails.tp_status == 2">
              <template v-if="toEdit">
                <td style="width: 30%">Cost Detail</td>
                <td>
                  <div>
            <textarea v-model="lossDetails.tp_cost_details" rows="5"
                      class="form-control form-control-sm pre-wrap-txt"></textarea>
                  </div>
                </td>
              </template>
            </tr>

            <tr v-if="!toEdit">
              <td style="width: 30%">Cost Detail</td>
              <td>
                <p class="pre-wrap-txt textarea-display-card mb-1">
                  {{
                    accidentIncidentReport.loss.tp_cost_details ? accidentIncidentReport.loss.tp_cost_details : 'N/A'
                  }}
                </p>
              </td>
            </tr>

          </table>
          <!--       THIRD PARTY  ///////////////////////////////////////////////////////////////////////////////////// -->
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MultiSelectCustom from "@/components/MultiSelectCustom";
import ImageComp from "@/components/elements/ImageComp";
import OtherComp from "@/components/elements/OtherComp";
import {FileService} from "@/services/FileService";
import {permissionMixin} from "@/mixins/permissionMixins";

const {ReportService} = require('../../../services/ReportService')
const {AlertService} = require('../../../services/AlertService')

export default {
  name: 'Loss',
  components: {MultiSelectCustom, ImageComp, OtherComp},
  data() {
    return {
      hullMachinerySelection: 1,
      currentIndex: null,
      lossDetails: {},
      personnelList: [],
      reportId: this.$route.params.id,
      toEdit: false,
      toEditPersonnel: false,
      attachments: [],
      errors: {},
      readOnly: true,
      personnelRecord: {
        crew_id: null,
        complaint: '',
        loss_details: '',
        personnel_costs: 0,
        currency_id: null,
        ailment_id: [],
        ailment_reason_id: null,
        ailment_part_id: [],
        ailment_details: '',
        medical_type_id: null,
        medical_treatment_id: null,
        medical_severity_id: null,
        last_name: '',
        first_name: '',
        middle_name: '',
      }
    }
  },
  mixins:[permissionMixin],
  methods: {
    ...mapActions([
      'getAccidentIncidentReport',
      'getCurrencyKeys',
      'getReports',
      'updateAccidentIncidentLoss',
      'deletePersonnel',
      'getMedicalTypeKeys',
      'getMedicalSeverityKeys',
      'getAilmentKeys',
      'getAilmentPartKeys',
      'getMedicalTreamentKeys',
      'getAilmentReasonKeys',
      'addLossPersonnel',
      'getAccidentIncidentLoss'
    ]),
    async cancel() {
      if (await AlertService.cancelAlert()) {
        this.toEdit = false;
        this.resetPersonnels()
      }
    },

    setTotalHours(day = 0, hours = 0, min = 0) {
      const totalHoursInDays= day * 24
      const totalHoursInMins= (min / 60)

      return Number((totalHoursInDays + hours) + totalHoursInMins).toFixed(2)
    },

    formatToMoney(amount) {
      return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    async addLossDetailsReport() {
      if (await AlertService.confirmUpdateAlert()) {
        this.lossDetails.id = this.reportId
        this.lossDetails.personnels = this.personnelList.filter(personnel => !!personnel.crew_id);
        const result = await this.updateAccidentIncidentLoss(this.lossDetails);
        if (result) {
          AlertService.successAlert('Updating Loss Action Details', 'UPDATE')
          this.toEdit = false;
        }
      }
    },
    async editValues() {
      this.toEdit = true
      if (this.toEdit) {
        this.lossDetails = Object.assign({}, this.accidentIncidentReport.loss)
        // this.personnelList = this.accidentIncidentReport.loss.personnel ? this.accidentIncidentReport.loss.personnel : this.personnelList
      }

    },
    resetPersonnelRecord() {
      this.toEditPersonnel = false
      this.readOnly = true
      this.currentIndex = null;
      this.attachments = [];
      this.errors = false;
      this.personnelRecord = {
        crew_id: null,
        complaint: '',
        loss_details: '',
        personnel_costs: 0,
        currency_id: null,
        ailment_id: [],
        ailment_reason_id: null,
        ailment_part_id: [],
        ailment_details: '',
        medical_type_id: null,
        medical_treatment_id: null,
        medical_severity_id: null,
        last_name: '',
        first_name: '',
        middle_name: '',
      }
    },
    async addInjuredPersonnel() {
      if (await AlertService.confirmCreateAlert('Do you want to add this crew?')) {
        const form = this.setFormData(this.personnelRecord);
        const response = await this.addLossPersonnel(form);
        if (typeof response === 'boolean' && response) {
          this.resetPersonnelRecord();
          $('#crewPersonnel').modal('hide');
          AlertService.successAlert('Crew has been added successfully','ADD INJURED PERSONNEL');
          await this.formatPersonnelLists(this.accidentIncidentReport.loss)
        } else {
          if (response.errors) {
            this.errors = response.errors;
          }
        }
      }
    },
    setFormData(formData) {
      const form = new FormData();
      form.append('air_loss_id', this.$route.params.id);
      Object.keys(formData).forEach((key) => {
        if (Array.isArray(formData[key]) && formData[key].length) {
          formData[key].forEach(item => {
            form.append(`${key}[]`, item);
          })
        } else {
          form.append(key, formData[key])
        }

      })
      const files = document.getElementById('attachments');
      if (files.files && files.files.length > 0) {
        Array.prototype.forEach.call(files.files, (file) => {
          form.append(`attachments[]`, file)
        });
      }
      return form;
    },
    async updateInjuredPersonnel() {
      if (await AlertService.confirmUpdateAlert('Do you want to update this crew?')) {
        const form = this.setFormData(this.personnelRecord);
        const response = await this.addLossPersonnel(form);
        if (typeof response === 'boolean' && response) {
          this.resetPersonnelRecord();
          $('#crewPersonnel').modal('hide');
          AlertService.successAlert('Crew has been updated successfully','UPDATE INJURED PERSONNEL');
          await this.formatPersonnelLists(this.accidentIncidentReport.loss)
        } else {
          if (response.errors) {
            this.errors = response.errors;
          }
        }
      }
    },
    async formatPersonnelLists(loss) {
      this.personnelList = [];
      const data = Object.assign([], JSON.parse(JSON.stringify(this.crewKeys)));
      if (!data) {
        return;
      }
      if (loss.hasOwnProperty('personnel') && loss.personnel.length) {
        loss.personnel.forEach(personnel => {
          const person = data.find(crew => crew.id === personnel.crew_id)
          this.personnelList.push({
            crew_id: person.id,
            last_name: person.last_name ?? '',
            first_name: person.first_name ?? '',
            middle_name: person.middle_name ?? '',
            age: person.age ?? null,
            rank: person.rank ?? '',
            manning: person.manning ?? '',
            embarkationDate: person.embarkationDate ?? '',
            duration: person.duration ?? '',
            nationality: person.nationality ?? '',
            id: personnel.id ?? null,
            ailment_reason_id: personnel.ailment_reason_id ?? null,
            medical_severity_id: personnel.medical_severity_id ?? null,
            medical_treatment_id: personnel.medical_treatment_id ?? null,
            medical_type_id: personnel.medical_type_id ?? null,
            currency_id: personnel.currency_id ?? null,
            air_loss_id: personnel.air_loss_id ?? null,
            ailment_id: personnel.ailment_id ?? [],
            ailment_part_id: personnel.ailment_part_id ?? [],
            complaint: personnel.complaint ?? '',
            lost_details: personnel.lost_details ?? '',
            personnel_costs: personnel.personnel_costs ?? '',
            ailment_details: personnel.ailment_details ?? ''
          })
        })
      }
    },

    changeCrewName(event, index = null) {;
      const data = Object.values(this.personnelList)
      if (index) {
        data.splice(index, 1);
      }
      const filteredCrew = data.filter(crew => crew.crew_id === this.personnelRecord.crew_id)
      if (filteredCrew.length > 0) {
        AlertService.errorAlert(`${filteredCrew[0].last_name}, ${filteredCrew[0].first_name} ${filteredCrew[0].middle_name} already exists in the list`, 'SELECT CREW')
        this.personnelRecord.crew_id = null;
        this.personnelRecord.last_name = '';
        this.personnelRecord.first_name = '';
        this.personnelRecord.middle_name = '';
      } else {
        var crewIndex = Object.values(this.crewKeys).findIndex(crew => crew.id == event.target.value)
        this.personnelRecord.last_name = this?.crewKeys[crewIndex]?.last_name ?? '';
        this.personnelRecord.first_name = this?.crewKeys[crewIndex]?.first_name ?? '';
        this.personnelRecord.middle_name = this?.crewKeys[crewIndex]?.middle_name ?? '';
      }
    },

    async viewPersonnel(personnel, index) {
      this.currentIndex = index;
      this.toEditPersonnel = true;
      this.personnelRecord = Object.assign({}, personnel)
      this.attachments = await ReportService.getPersonnelAttachments({air_loss_personnel_id: personnel.id});
    },

    async downloadPersonnelAttachment(attachment) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const response = await ReportService.getPersonnelAttachment({id: attachment.id});
      if (response) {
        await FileService.downloadDocument(response, attachment)
        swal.close();
      }
    },

    async viewPersonnelAttachment(attachment) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const response = await ReportService.getPersonnelAttachment({id: attachment.id});
      if (response) {
        if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
          await FileService.viewDocument(response, attachment)
        } else {
          await FileService.downloadDocument(response, attachment);
        }
        swal.close();
      }
    },

    async removePersonnelAttachment(attachment) {
      if (await AlertService.confirmDeleteAlert(null, 'Are you sure you want to delete this attachment?', 'Yes, delete attachment')) {
        swal.fire({
          title: 'Deleting attachment please wait...',
        });
        swal.showLoading();
        const response = await ReportService.deletePersonnelAttachment(attachment)
        if (response.success) {
          swal.close();
          this.attachments = this.attachments.filter(attachmentInfo => attachmentInfo.id !== attachment.id);
          await AlertService.successAlert('ATTACHMENT DELETED SUCCESSFULLY', 'REMOVE ATTACHMENT');
        }
      }
    },


    async removePersonnel(id) {
      if (await AlertService.confirmDeleteAlert('Do you want to remove this personnel from the list ?')) {
        const response = await this.deletePersonnel({id: id})
        if (response) {
          const loss = await this.getAccidentIncidentLoss({id: this.$route.params.id})
          if (loss) {
            this.personnelList = this.personnelList.filter(person => person.id !== id);
          }
          AlertService.successAlert('Personnel removed successfully', 'REMOVE PERSONNEL');
        } else {
          AlertService.errorAlert('Personnel remove encounter an error', 'REMOVE PERSONNEL');
        }
      }
    },
    setAilments(event) {
      this.personnelRecord.ailment_id = event.selected
    },
    setAilmentParts(event) {
      this.personnelRecord.ailment_part_id = event.selected
    }
  },


  async created() {
    await this.getMedicalTypeKeys();
    await this.getMedicalSeverityKeys();
    await this.getAilmentKeys();
    await this.getAilmentPartKeys();
    await this.getMedicalTreamentKeys();
    await this.getAilmentReasonKeys();
    this.formatPersonnelLists(this.accidentIncidentReport.loss)
  },

  computed: {
    ...mapGetters([
      'accidentIncidentReport',
      'currencies',
      'reports',
      'crewKeys',
      'medicalTypeKeys',
      'medicalSeverityKeys',
      'ailmentKeys',
      'ailmentPartKeys',
      'medicalTreatmentKeys',
      'ailmentReasonKeys'
    ]),
    ailmentKey() {
      if (this.personnelRecord.medical_type_id) {
        return this.ailmentKeys.filter(ailment => ailment.medical_type_id === this.personnelRecord.medical_type_id)
      }
      return this.ailmentKeys;
    },
    entity() {
      if (this.personnelRecord.medical_type_id) {
        const medicalType = this.medicalTypeKeys.filter(medicalType => medicalType.id === this.personnelRecord.medical_type_id)
        return medicalType[0].name
      }
      return this.medicalTypeKeys.map(medKey => {
        return medKey.name.charAt(0).toUpperCase() + medKey.name.slice(1)
      }).slice(0, 2).join(' / ');
    }
  },
  watch: {
    'lossDetails.loss_hull_status'() {
      if (this.lossDetails.loss_hull_status == 1) {
        this.lossDetails.loss_hull_cost = ''
        this.lossDetails.loss_hull_currency = ''
        this.lossDetails.loss_hull_detail = ''
      }
    },

    'lossDetails.loss_cargo_status'() {
      if (this.lossDetails.loss_cargo_status == 1) {
        this.lossDetails.loss_cargo_cost = ''
        this.lossDetails.loss_cargo_currency = ''
        this.lossDetails.loss_cargo_detail = ''
      }
    },

    'lossDetails.loss_environmental_status'() {
      if (this.lossDetails.loss_environmental_status == 1) {
        this.lossDetails.loss_environmental_cost = ''
        this.lossDetails.loss_environmental_currency = ''
        this.lossDetails.loss_environmental_detail = ''
      }
    },

    'lossDetails.loss_third_party_status'() {
      if (this.lossDetails.loss_third_party_status == 1) {
        this.lossDetails.loss_third_party_cost = ''
        this.lossDetails.loss_third_party_currency = ''
        this.lossDetails.loss_third_party_detail = ''
      }
    },

    'lossDetails.is_delay_schedule'(){
      if (!this.lossDetails.is_delay_schedule) {
        this.lossDetails.delay_sched_day = 0
        this.lossDetails.delay_sched_hour = 0
        this.lossDetails.delay_sched_minute = 0
        this.lossDetails.delay_sched_remarks = ''
      }
    }
  },

}
</script>

<style scoped lang="scss">
.modal:nth-of-type(even) {
  z-index: 1052 !important;
}

.bg-highlight-red {
  background-color: #ffb7b7;
}

.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}

.nav > .nav-link {
  padding: 8px 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #435464;
  margin-bottom: -1;
}

.nav > .active, .nav > .nav-link:hover {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

table td {
  padding: 2px;
}
</style>
